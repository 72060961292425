<template>
  <div>
    <div class="wrapper">
      <router-view />
    </div>
  </div>
</template>
<script>
import { core } from '../config/pluginInit'
export default {
  name: 'BlankLayout',

  mounted () {
    core.mainIndex()
  }
}
</script>
<style>
  @import url("../assets/css/custom.css");
  .modal-backdrop {
    opacity: .5;
  }
</style>
